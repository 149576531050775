import { ElasticTournament } from '@spikemark/rest-api';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../components/ui/tooltip';
import { CheckCircle, Radio } from 'lucide-react';
import { FC } from 'react';
import { BaseBadge } from './base-badge';
import { useClientConfig } from '@spikemark/core';
import { cn } from '@spikemark/ui/utils/cn';
type LiveBadgeProps = {
  status: 'live' | 'upcoming' | 'completed' | null;
  learnMoreUrl: string;
};
export const determineBadgeType = (tournament: ElasticTournament): LiveBadgeProps['status'] => {
  if (tournament.tournamentIntegrationId === 'scoreboardlive') {
    if (tournament.isComplete) {
      return 'completed';
    } else if (new Date(tournament.startDate) > new Date()) {
      return 'upcoming';
    } else {
      return 'live';
    }
  } else {
    return null;
  }
};
export const LiveBadgeBase: FC<LiveBadgeProps> = ({
  status,
  learnMoreUrl
}) => {
  if (status === null) {
    return <></>;
  }
  let icon = <CheckCircle className="h-4 stroke-[3px]" />;
  if (status === 'live') {
    icon = <Radio className="h-5 stroke-2" />;
  } else if (status === 'upcoming') {
    icon = <Radio className="h-5 stroke-2" />;
  }
  return <Tooltip data-sentry-element="Tooltip" data-sentry-component="LiveBadgeBase" data-sentry-source-file="live-badges.tsx">
      <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="live-badges.tsx">
        <BaseBadge className={cn('cursor-default select-none', {
        'bg-red-500 text-white': status === 'live',
        'bg-white text-red-500 border border-gray-300': status === 'upcoming',
        'bg-emerald-50 border border-emerald-200 text-green-700': status === 'completed'
      })} icon={icon} data-sentry-element="BaseBadge" data-sentry-source-file="live-badges.tsx">
          ScoreboardLive
        </BaseBadge>
      </TooltipTrigger>
      <TooltipContent data-sentry-element="TooltipContent" data-sentry-source-file="live-badges.tsx">
        <div className="flex flex-col space-y-2 p-2">
          <p className="text-lg font-bold text-gray-900">Live Scoring</p>
          <p className="text-gray-600">
            Live scoring is only shown on the website for
            <br /> tournaments using ScoreboardLive.
          </p>
          <p className="text-gray-600">
            Learn more about ScoreboardLive{' '}
            <a className="underline text-bold" href={learnMoreUrl}>
              here.
            </a>
          </p>
        </div>
      </TooltipContent>
    </Tooltip>;
};
export const LiveBadge: FC<Omit<LiveBadgeProps, 'learnMoreUrl'>> = ({
  status
}) => {
  const clientConfig = useClientConfig();
  return <LiveBadgeBase status={status} learnMoreUrl={clientConfig.scoreboardLiveLearnMoreUrl} data-sentry-element="LiveBadgeBase" data-sentry-component="LiveBadge" data-sentry-source-file="live-badges.tsx" />;
};